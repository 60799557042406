import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingDiscounts: [],
    };
  },
  computed: {
    ...mapFields('discount', {
      discountIds: 'allIds',
      discountIdsCached: "cacheAllIds",
      deletedDiscountItem: 'deleted',
      discountHandled: 'handled',
      discountActionHandled: 'handleAction'
    }),
    ...mapGetters('discount', {findDiscount: 'find'}),
  },
  methods: {
    ...mapActions('discount', {
      retrieveDiscount: 'load',
      deleteDiscount: 'del',
      updateDiscount: 'update',
    }),
    resolveDiscountItems(itemIds) {
      if(typeof itemIds === "undefined"){
        return [];
      }
      return itemIds.map((itemId) => {
        if (!this.discountIdsCached.includes(itemId) && !this.loadingDiscounts.includes(itemId)) {
          this.loadingDiscounts.push(itemId);
          this.retrieveDiscount(decodeURIComponent(itemId));
        }
        return this.findDiscount(itemId);
      }).filter(e => e);
    },
  }
};
