<template>
  <v-form style="position: relative">
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <CustomerSearch
            :on-selected="onCustomerAcUpdated"
          />
          <v-textarea
            :error-messages="addressTextErrors"
            :label="$t('addressText')"
            @blur="$v.item.addressText.$touch()"
            @input="$v.item.addressText.$touch()"
            v-model="item.addressText"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <InputDate
            :error-messages="dateErrors"
            :label="$t('offerDate')"
            required
            v-model="item.date"
            @blur="$v.item.date.$touch()"
            @input="$v.item.date.$touch()"
          />
          <v-alert :type="item['@id'] ? 'success' : 'info'" outlined text class="mt-8 ml-9">
            <template v-if="item['@id']"> {{ $t('offerNumber') }}: {{ item.number }} </template>
            <template v-else>
              {{ $t('autoOfferNumber') }}
            </template>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <h4>{{ $t('headerText') }}</h4>
          <TextTemplateSelector @textTemplateSelected="templateSelected('headerText', ...arguments)" />
          <InputEditor
            v-model="item.headerText"
            :error-messages="headerTextErrors"
            :required="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <template v-if="item['@id']">
            <PositionListAndAdd
              :default-item-props="{ offer: this.item['@id'] }"
              :positions="this.item.positions"
              object-type="offer"
              :handle-message="handleMessage"
              :update-parent="this.updateOffer"
              :show-title="false"
              @updatePositions="updatePositionHandler"
            />
          </template>
          <template v-else>
            <v-alert text type="info">
              {{ $t('saveOfferFirst') }}
            </v-alert>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <h4>{{ $t('footerText') }}</h4>
          <TextTemplateSelector @textTemplateSelected="templateSelected('footerText', ...arguments)" />
          <InputEditor
            v-model="item.footerText"
            :error-messages="footerTextErrors"
            :required="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <DiscountListAndAdd
            :default-item-props="{ offer: this.item['@id'] }"
            :show-message="showMessage"
            :discounts="this.item.discounts"
            :show-title="true"
            object-type="offer"
            @updateDiscounts="updateDiscountsHandler"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"> </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="isFrozen" :absolute="true"></v-overlay>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import CustomerMixin from '@/mixins/CustomerMixin';
import ProfileMixin from '@/mixins/ProfileMixin';
import AddressMixin from '@/mixins/AddressMixin';
import TextTemplateMixin from '@/mixins/TextTemplateMixin';
import { date } from '@/validators/date';
import InputDate from '@/components/InputDate';
import PositionMixin from '@/mixins/PositionMixin';
import PositionListAndAdd from '@/components/position/ListAndAdd';
import OfferMixin from '@/mixins/OfferMixin';
import TenantMixin from '@/mixins/TenantMixin';
import InputEditor from '@/components/InputEditor';
import DialogMixin from '@/mixins/DialogMixin';
import DiscountListAndAdd from '@/components/discount/ListAndAdd';
import NotificationMixin from '../../mixins/NotificationMixin';
import TextTemplateSelector from '@/components/textTemplate/Selector';
import CustomerSearch from '@/components/customer/Search.vue';

export default {
  name: 'OfferForm',
  mixins: [
    validationMixin,
    CustomerMixin,
    ProfileMixin,
    AddressMixin,
    TextTemplateMixin,
    PositionMixin,
    OfferMixin,
    TenantMixin,
    DialogMixin,
    NotificationMixin
  ],
  components: {
    CustomerSearch,
    InputDate,
    PositionListAndAdd,
    InputEditor,
    DiscountListAndAdd,
    TextTemplateSelector
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    initialValues: {
      type: Object,
      default: () => {}
    },
    handleMessage: {
      type: Function,
      required: true
    }
  },
  mounted() {
    this.applyDefaultHeaderText();
    this.applyDefaultFooterText();
  },
  data() {
    return {
      currentCustomer: null,
    };
  },
  computed: {
    defaultHeaderTextTemplate() {
      if (!this.item.headerText && this.currentAccountSettings['defaultOfferHeaderText']) {
        return this.resolveTextTemplate(this.currentAccountSettings['defaultOfferHeaderText']);
      }
      return null;
    },
    defaultFooterTextTemplate() {
      if (!this.item.footerText && this.currentAccountSettings['defaultOfferFooterText']) {
        return this.resolveTextTemplate(this.currentAccountSettings['defaultOfferFooterText']);
      }
      return null;
    },
    positions() {
      if (this.item.positions) {
        return this.resolvePositions(this.item.positions);
      }
      return [];
    },
    itemCustomer() {
      return this.item.customer;
    },
    isFrozen() {
      return !!(this.item && this.item.frozen);
    },
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    // region validation
    addressTextErrors() {
      const errors = [];

      if (!this.$v.item.addressText.$dirty) {
        return errors;
      }

      has(this.violations, 'addressText') && errors.push(this.violations.addressText);

      !this.$v.item.addressText.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    headerTextErrors() {
      const errors = [];

      if (!this.$v.item.headerText.$dirty) {
        return errors;
      }
      has(this.violations, 'headerText') && errors.push(this.violations.headerText);
      !this.$v.item.headerText.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    footerTextErrors() {
      const errors = [];

      if (!this.$v.item.footerText.$dirty) {
        return errors;
      }
      has(this.violations, 'footerText') && errors.push(this.violations.footerText);
      !this.$v.item.footerText.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.item.date.$dirty) {
        return errors;
      }

      has(this.violations, 'date') && errors.push(this.violations.date);

      !this.$v.item.date.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, 'enabled') && errors.push(this.violations.enabled);

      return errors;
    },
    lockedErrors() {
      const errors = [];

      if (!this.$v.item.locked.$dirty) {
        return errors;
      }

      has(this.violations, 'locked') && errors.push(this.violations.locked);

      return errors;
    },
    violations() {
      return this.errors || {};
    }
    //endregion
  },
  methods: {
    log(message) {
      console.log(message);
    },
    applyAddress(profile = null, address = null) {
      let newAddressText = '';
      if (address === null || profile == null) {
        newAddressText = '';
      } else {
        if (profile.company) {
          newAddressText = profile.company + '\nz.H ';
        }
        if (profile.title) {
          newAddressText += profile.title + ' ' + '\n';
        }
        newAddressText += profile.firstName + ' ' + profile.lastName + '\n';
        newAddressText += address.streetNr + '\n' + address.zipCode + ' ' + address.city;
      }

      this.$set(this.item, 'addressText', newAddressText);
    },
    templateSelected(target, textTemplate) {
      if (textTemplate && target) {
        this.$set(this.item, target, textTemplate);
      }
    },
    onCustomerAcUpdated(selectedCustomer) {
      if (selectedCustomer) {
        this.currentCustomer = selectedCustomer;
        this.item.customer = selectedCustomer['@id'];
      } else {
        this.currentCustomer = null;
        this.item.customer = null;
      }
    },
    updatePositionHandler(updatedPositions) {
      this.item.positions = updatedPositions;
    },
    applyDefaultHeaderText() {
      if (this.defaultHeaderTextTemplate && !this.item.headerText) {
        this.templateSelected('headerText', this.defaultHeaderTextTemplate.content);
      }
    },
    applyDefaultFooterText() {
      if (this.defaultFooterTextTemplate && !this.item.footerText) {
        this.templateSelected('footerText', this.defaultFooterTextTemplate.content);
      }
    },
    updateDiscountsHandler(updatedDiscounts) {
      this.item.discounts = updatedDiscounts;
    },
    customerFilter(item, queryText) {
      return item.customerNumber.toLowerCase().indexOf(queryText.toLowerCase()) !== -1;
    }
  },
  watch: {
    currentCustomer(newVal, oldVal) {
      if (
        this.currentCustomer &&
        oldVal === null &&
        !(
          this.item.addressText === null ||
          typeof this.item.addressText === 'undefined' ||
          this.item.addressText.length === 0
        )
      ) {
        return;
      }

      if (this.currentCustomer) {
        let profileId = this.currentCustomer.profile;
        if (typeof profileId === 'object') {
          profileId = profileId['@id'];
        }
        this.resolveProfileAsync(profileId).then((profile) => {
          this.resolveAddressesAsync(profile.addresses).then((addresses) => {
            let pickedAddress = null;
            if (addresses) {
              addresses.forEach((address) => {
                if (pickedAddress === null) {
                  pickedAddress = address;
                }
                if (address.type === 'invoice') {
                  pickedAddress = address;
                }
              });
            }
            this.item.customer = this.currentCustomer['@id'];
            if (pickedAddress === null) {
              this.showError(this.$t('Kunde hat keine Adresse hinterlegt.'));
            }
            this.applyAddress(profile, pickedAddress);
          });
        });
      }
      if (!newVal && oldVal) {
        this.item.customer = null;
        this.applyAddress();
      }
    },
    itemCustomer(currentItemCustomer) {
      if (currentItemCustomer) {
        this.currentCustomer = this.resolveCustomer(currentItemCustomer);
      }
    },
    defaultHeaderTextTemplate() {
      this.applyDefaultHeaderText();
    },
    defaultFooterTextTemplate() {
      this.applyDefaultFooterText();
    }
  },
  validations: {
    item: {
      date: {
        required,
        date
      },
      addressText: {
        required
      },
      headerText: {
        required
      },
      footerText: {
        required
      }
    }
  }
};
</script>
