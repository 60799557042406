<template>
  <v-card
    flat
    :loading="!discountsLoaded"
  >
    <v-card-title v-if="showTitle">{{ $t('Discounts') }}</v-card-title>
    <v-card-text v-if="discountsLoaded && discountObjects.length > 0">
      <v-data-table
        :headers="discountHeaders"
        :items="discountObjects"
        :loading="!discountsLoaded"
        :hide-default-footer="true"
        :loading-text="$t('Loading...')"
        class="elevation-1"
        item-key="@id"
      >
        <template
          slot="item.type"
          slot-scope="{ item }"
        >
          {{ $t(item.type) }}
        </template>
        <template
          slot="item.description"
          slot-scope="{ item }"
        >
          {{ $t(item.description) }}
        </template>
        <template
          slot="item.validFrom"
          slot-scope="{ item }"
        >
          {{ item.validFrom ? formatDateTime(item.validFrom, 'DD.MM.YYYY') : '-' }}
        </template>
        <template
          slot="item.validTo"
          slot-scope="{ item }"
        >
          {{ item.validTo ? formatDateTime(item.validTo, 'DD.MM.YYYY') : '-' }}
        </template>
        <div
          slot="item.action"
          slot-scope="props"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="createOrEditDiscountHandler(props.item)"
                class="mr-2"
                small
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('Edit') }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="hasRole('ROLE_DELETE_DISCOUNT')"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="handleDelete(props.item['@id'])"
                class="mr-2"
                small
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="hasRole('ROLE_CREATE_DISCOUNT') && isDiscountable && invoiceNotCreated"
        @click="createOrEditDiscountHandler(null)"
        color="success"
      >
        {{ $t('AddDiscount') }}
      </v-btn>
      <DiscountDialog
        v-if="hasRole('ROLE_UPDATE_DISCOUNT') || hasRole('ROLE_CREATE_DISCOUNT')"
        v-model="showDiscountDialogForm"
        :discount-item="discountFormItem"
        :handle-created="onCreated"
        :handle-updated="onUpdated"
        :show-handle="showDiscountDialogForm"
        :title="$t('AddDiscount')"
        @close="discountDialogControl(true)"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
  import DiscountDialog from "@/components/discount/Dialog";
  import DiscountMixin from "@/mixins/DiscountMixin"
  import remove from "lodash/remove";
  import TenantMixin from "@/mixins/TenantMixin";
  import { formatDateTime } from '@/utils/dates';

  export default {
    name: 'DiscountListAndAdd',
    components: {
      DiscountDialog
    },
    mixins: [DiscountMixin, TenantMixin],
    props: {
      values: {
        type: Object,
        required: false
      },
      discounts: {
        type: Array,
        default: () => []
      },
      discountsCount: {
        type: Number,
        default: 0
      },
      defaultItemProps: {
        type: Object,
        required: true,
      },
      showMessage: {
        type: Function,
        required: true
      },
      showTitle: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      objectType: {
        type: String,
        required: true
      },
      isDiscountable: {
        type: Boolean,
        required: false,
        default: true
      },
      invoice: {
        type: String,
        required: false
      },
    },
    data() {
      return {
        discountFormItem: {},
        showDiscountDialogForm: false,
        discountHeaders: [
          { text: this.$i18n.t('DiscountType'), value: 'type' },
          { text: this.$i18n.t('discountDescription'), value: 'description' },
          { text: this.$i18n.t('DiscountAmount'), value: 'amount' },
          { text: this.$i18n.t('ValidFrom'), value: 'validFrom' },
          { text: this.$i18n.t('ValidTo'), value: 'validTo' },
          {
            text: this.$i18n.t('Actions'),
            value: 'action',
            sortable: false,
            align: 'end'
          }
        ],
      };
    },
    computed: {
      discountObjects() {
        return this.resolveDiscountItems(this.discounts);
      },
      discountsLoaded() {
        return (this.discounts.length === this.discountObjects.length);
      },
      invoiceNotCreated() {
        return (typeof this.invoice === "undefined" || this.invoice === null);
      }
    },
    methods: {
      log(message) {
        console.log(message);
      },
      formatDateTime,
      onCreated(item) {
        this.discounts.push(item['@id']);
        this.$emit('updateDiscounts', this.discounts);
      },
      onUpdated(item) {
        this.showMessage(item['@id'] + ' updated');
      },
      createOrEditDiscountHandler(discountItem) {
        console.log(discountItem, this.defaultItemProps)
        this.discountFormItem = discountItem ?? this.defaultItemProps;
        this.discountDialogControl();
      },
      discountDialogControl(close = false) {
        this.showDiscountDialogForm = !close;
      },
      deleteDiscountHandler(item) {
        var cleaned = remove(this.discounts, (uid) => {
          return !(uid === item['@id']);
        });
        this.$emit('updateDiscounts', cleaned);
        this.deleteDiscount(item);
      },
      handleDelete(discount) {
        this.$confirm(this.$t('Are you sure you want to delete this item?'), {
          buttonTrueText: this.$t('Delete'),
          buttonFalseText: this.$t('Cancel'),
        }).then((response) => {
          if (!response) {
            return;
          }
          this.deleteDiscountHandler(discount);
        });
      },
    },
    watch: {
      deletedDiscountItem() {
        this.showMessage(this.$t('DiscountDeleted'));
      },
      discountHandled() {
        if (this.discountHandled === null) {
          return;
        }
        if (this.discountActionHandled === 'invite' && this.discountHandled.invited === true) {
          this.showMessage(this.$t('DiscountInvited'));
          this.discountActionHandled = null;
          this.discountHandled = null;
        }
      },
    },
  }
</script>
